import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

import Spinner from '../spinner/Spinner';

import styles from './Button.module.scss';

/* Local constants & types
============================================================================= */
type Ref = HTMLButtonElement;

/* <Button />
============================================================================= */
type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'link';
  isAnimating?: boolean;
};

/* <Button />
============================================================================= */
const Button = forwardRef<Ref, Props>(
  (
    {
      onClick,
      className,
      type,
      children,
      variant = '',
      isAnimating = false,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      className={classNames(
        'rounded-md',
        styles.base,
        { [styles[variant]]: variant, 'w-full': variant === 'primary' },
        className
      )}
      type={type}
      onClick={onClick}
      {...props}>
      {children}
      {isAnimating ? <Spinner /> : null}
    </button>
  )
);

export default Button;
