import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useAuth } from 'common/hooks/useAuth';
import routes from 'common/utils/routes';
import Button from '../buttons/Button';
import Logo from './Logo';
import Dropdown from '../dropdown/Dropdown';
import Avatar from '../avatar/Avatar';

/* <Header />
============================================================================= */
const Header = () => {
  const router = useRouter();
  const auth = useAuth();

  const logout = () => {
    auth.signOut().then(() => {
      router.push(routes.login);
    });
  };

  return (
    <header className="header py-6">
      <nav className="container flex justify-between items-center">
        <Logo href={!auth.user ? routes.home : routes.dashboard} />

        {router.pathname === routes.home && (
          <Link href={routes.login} passHref>
            <Button variant="secondary">Особистий кабінет</Button>
          </Link>
        )}

        {auth.isInitialized && auth.user && (
          <Dropdown
            value={
              <div className="flex items-center gap-3">
                <Avatar src={auth.user?.photoURL} size="sm" />

                <span className="max-w-[6rem] overflow-hidden overflow-ellipsis md:max-w-[10rem]">
                  {auth.user?.displayName}
                </span>
              </div>
            }>
            <Button onClick={() => router.push(routes.profile)}>Профіль</Button>
            <Button onClick={logout}>Вийти</Button>
          </Dropdown>
        )}
      </nav>
    </header>
  );
};

export default Header;
