import React, { ReactNode } from 'react';
import classNames from 'classnames';

import useOutsideClick from 'common/hooks/useOutsideClick';
import Icon, { Icons } from '../icon/Icon';

import styles from './Dropdown.module.scss';

/* Props - <Dropdown />
============================================================================= */
type Props = {
  children?: ReactNode;
  title?: string;
  value: string | ReactNode;
  isOpen?: boolean;
  disabled?: boolean;
};

/* <Dropdown />
============================================================================= */
const Dropdown: React.FC<Props> = ({
  value,
  children,
  isOpen: isDropdownOpened = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(isDropdownOpened);
  const ref = useOutsideClick<HTMLDivElement>(() => {
    setIsOpen(false);
  });

  return (
    <div ref={ref} className={styles.dropdown}>
      <button
        className={styles.dropdownToggle}
        onClick={() => setIsOpen((prevState) => !prevState)}
        title={typeof value === 'string' ? value : ''}
        {...props}>
        {value}
        <Icon
          className={classNames(styles.dropdownIcon)}
          variant={Icons.chevronRight}
        />
      </button>

      <ul className={classNames(styles.dropdownMenu, { hidden: !isOpen })}>
        {React.Children.map(
          children,
          (child, index) =>
            child && (
              <li
                className={styles.dropdownItem}
                key={`dropdown-menu-item-${index}`}
                onClick={() => setIsOpen(false)}>
                {child}
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default Dropdown;
// export { DropdownButton } from './Dropdown.module.scssdown/Dropdown.styles';
