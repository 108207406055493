import React, { useState } from 'react';
import classNames from 'classnames';

import Icon, { Icons } from '../icon/Icon';
import Image from 'next/image';

/* Local constants & types
============================================================================= */

/* Props - <Accordion />
============================================================================= */
type Props = {
  size: 'sm' | 'lg';
  src?: string | null;
  className?: string;
};

/* <Avatar />
============================================================================= */
const Avatar: React.FC<Props> = ({ src, size, className }) => {
  return (
    <figure
      className={classNames('relative', className, {
        'w-6 h-6': size === 'sm',
        'w-28 h-28': size === 'lg',
      })}>
      {!src ? (
        <Icon
          className={classNames('text-xl', {
            'text-8xl': size === 'lg',
          })}
          variant={Icons.user}
        />
      ) : (
        <Image
          className={classNames('rounded-full overflow-hidden')}
          src={src}
          alt=""
          fill
        />
      )}
    </figure>
  );
};

export default Avatar;
