import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

/* Props - <Logo />
============================================================================= */
type Logo = {
  href: string;
};

/* <Logo />
============================================================================= */
const Logo: React.FC<Logo> = ({ href }) => {
  return (
    <Link href={href}>
      <figure className="flex items-center font-jedi">
        <Image src="/logo.svg" width="80" height="58" alt="" />
        <figcaption className="max-[450px]:visibility-hidden">
          kittie.tech
        </figcaption>
      </figure>
    </Link>
  );
};

export default Logo;
