import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

/* Local constants & types
============================================================================= */
export enum Icons {
  inkedin = 'linkedin',
  telegram = 'telegram',
  tiktok = 'tiktok',
  instagram = 'instagram',
  facebook = 'facebook',
  youtube = 'youtube',
  chevronLeft = 'chevron-left',
  chevronRight = 'chevron-right',
  google = 'google',
  user = 'user',
  edit = 'edit',
  upload = 'upload',
  addLandscape = 'add-landscape',
  removeLandscape = 'remove-landscape',
  halfFillCircle = 'half-fill-circle',
  code = 'code',
  video = 'video',
  paragraph = 'paragraph',
  circle = 'circle',
  checkInCircle = 'check-in-circle',
  settings = 'settings',
  home = 'home',
  lock = 'lock',
}

/* Props - <Icon />
============================================================================= */
type Props = {
  variant: Icons;
  className?: string;
};

/* <Icon />
============================================================================= */
const Icon: React.FC<Props> = ({ variant, className }) => {
  return (
    <i
      className={classNames(
        'text-2x',
        styles.icon,
        styles[`icon-${variant}`],
        className
      )}
      aria-hidden="true"></i>
  );
};

export default Icon;
