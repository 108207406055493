import React from 'react';
import classNames from 'classnames';

import Icon, { Icons } from '../icon/Icon';

import styles from './Footer.module.scss';

/* Local constants & types
============================================================================= */
const SOCIAL_NETWORK_LIST = [
  {
    icon: Icons.telegram,
    link: '//t.me/frontend_kittie',
  },
  {
    icon: Icons.facebook,
    link: '//www.facebook.com/profile.php?id=100089913979218',
  },
  {
    icon: Icons.youtube,
    link: '//www.youtube.com/@kittietech',
  },
];

/* <Footer />
============================================================================= */
const Footer = () => {
  return (
    <div className="relative container z-10">
      <div className="mb-10 flex w-44 justify-between">
        {SOCIAL_NETWORK_LIST.map(({ icon, link }) => {
          return (
            <a
              key={icon}
              href={link}
              className={classNames(styles.socialNetwork, styles[icon])}>
              <Icon variant={icon} />
            </a>
          );
        })}
      </div>

      <small className={classNames('font-sm', styles.background)}>
        Сopyright &copy; kittie.tech 2023
      </small>
    </div>
  );
};

export default Footer;
