import React from 'react';
import classNames from 'classnames';

import styles from './Ellipse.module.scss'

/* Props - <Ellipse />
============================================================================= */
type Props = {
  variant: 'red' | 'blue';
  size: 'sm' | 'md' | 'lg';
  className?: string;
}

/* <Ellipse />
============================================================================= */
const Ellipse: React.FC<Props> = ({ variant, size = 'lg', className }) => {
  return (
    <i className={classNames(
      "absolute rounded-full inset-0 m-auto -z-1",
      {
        "bg-red-gradient shadow-red": variant === 'red',
        "bg-blue-gradient shadow-blue": variant === 'blue',
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
      },
      className
    )}
      role="img"
      aria-hidden="true"
    ></i>
  )
}

export default Ellipse;