import React from 'react';
import classNames from 'classnames';

import styles from './SubHeading.module.scss';

/* Props - <SubHeading />
============================================================================= */
type Props = {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  className?: string;
  children?: React.ReactNode;
};

/* <SubHeading />
============================================================================= */
const SubHeading: React.FC<Props> = ({ as = 'h2', className, children }) => {
  const As = as;

  return (
    <As className={classNames('mb-10', styles.title, className)}>{children}</As>
  );
};

export default SubHeading;
