import React from 'react';
import { FieldError, Merge } from 'react-hook-form';

/* Props - <ErrorMessage />
============================================================================= */
type Props = {
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
};

/* <ErrorMessage />
============================================================================= */
const ErrorMessage: React.FC<Props> = ({ error }) => {
  return (
    <>
      {error && (
        <p className="text-sm text-red-500 mt-3 mb-0">{error.message}</p>
      )}
    </>
  );
};

export default ErrorMessage;
