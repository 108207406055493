import React from 'react';

import Header from '../header/Header';
import Footer from '../Footer/Footer';

/* Props <Layout />
============================================================================= */
type Props = {
  children?: React.ReactNode;
};

/* <Layout />
============================================================================= */
const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <style jsx global>{`
        html,
        body,
        #__next {
          background-image: linear-gradient(
              180deg,
              #050304 0%,
              rgba(5, 3, 4, 0) 51.04%,
              #050304 100%
            ),
            url('/bg-space.svg');
          background-repeat: no-repeat;
          background-size: auto 1264px, auto 1264px;
          background-position: top center;
        }
      `}</style>

      <Header />
      <main className="main">{children}</main>

      <footer className="footer flex items-end">
        <Footer />
      </footer>
    </>
  );
};

export default Layout;
