import React, { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement>;
export type Ref = HTMLInputElement;

/* <Input />
============================================================================= */
const Input = forwardRef<Ref, Props>(
  ({ onClick, className, type, ...props }, ref) => (
    <input
      className={classNames(styles.formControl, className)}
      ref={ref}
      type={type}
      onClick={onClick}
      autoComplete="off"
      {...props}
    />
  )
);

export default Input;
